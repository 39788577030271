export const Data = {
  profile: {
    name: "Stevens Adedipe",
    ocupation: "DevOps Engineer",
    location: "Wolverhampton, United Kingdom",
    email: "stevens@adedipe.dev",
    telephone: "+447526547631",
    image: "images/IMG.png",
    web: "https://linkedin.com/in/stevens-a-6a1aab20/"
  },
  aboutMe: {
    label: "Profile",
    description:
      "A bright, talented and ambitious DevOps Engineer/SRE with a strong technical background who possesses self-discipline and the ability to work with the minimum of supervision. Having exposure to a wide range of technologies & able to play a key role in Analysis, diagnosing hardware, software problems, and to ensure that quality solutions meet business objectives. Possessing a good team spirit, deadline orientated and having the ability to organize and present complex solutions clearly and accurately.",
  },
  skills: {
    technicalLabel: "Tech Stack",
    softLabel: "Skills",
    technicalSkills: [
      "Docker",
      "Kubernetes",
      "Git",
      "Python",
      "ReactJS",
      "API",
      "SQL",
      "NoSQL",
      "IAC",
      "Terraform",
      "Cloudformation",
      "CloudBuild",
      "CoPilot",
      "Jenkins",
      "CI/CD",
      "AWS",
      "Azure",
      "GCP",
      "Linux",
      "Power BI",
      "IBM Cognos analytics"

    ],
    softSkills: [
      "Critical Thinking",
      "Cost Management",
      "Collaboration",
      "Software Development",
      "Deployment Strategies",
      "Communication",
      "Reporting and Presentation",
      "Project Management",
      "Data Management",
      "Creativity",
      "Proactivity",
      "Mentoring",
    ],
  },
  socialMedia: {
    label: "SOCIAL",
    social: [
      {
        label: "Linkedin",
        name: "linkedin",
        url: "https://linkedin.com/in/stevens-a-6a1aab20/",
        className: "bxl-linkedin-square",
      },
      {
        label: "Github",
        name: "github",
        url: "https://github.com/ztevens",
        className: "bxl-github",
      },
      {
        label: "twitter",
        name: "twiiter",
        url: "https://twitter.com/beryour",
        className: "bxl-twitter",
      },
    ],
  },
  experience: {
    works: [
      {
        title: "DevOps Engineer",
        period: "June 2022 - Date",
        company: "i6",
        description: [
          "Monitoring and resolving tickets on the jira board.",
          "Looking after the DevOps process, including the creation of CI/CD pipelines and continuous deployment .",
          "Maintain Git workflows for version control (Source Code Management).",
          "Implementation of automated testing pipelines, including the creation of test suites, test cases, and test automation scripts.",
          "Building dashboard and reporting of the automation testing process.",
          "Monitoring and reporting of production deployments.",
          "Using IaC to deploy resources and infrastructures.",
        ],
      },
      {
        title: "DevOps Engineer",
        period: "January 2020 - June 2022",
        company: "Container Code",
        description: [
          "Responsible for implementation and championing of the DevOps process, including the creation of CI/CD pipelines and continuous deployment.",
          "Building dashboard and reporting of the automation testing process.",
          "Monitoring and reporting of production deployments.",
          "Using Docker to containerize with AWS integration enabled features.",
          "Maintenace of platforms and their infrastructure",
          "Collaboration with the dev and product team to ensure that the platforms are up to date and stable.",
          "Maintaining GIT workflows for version control.",
          "Using IaC to build infrastructures and resources",
          "Planning and organizing tickets through Azure boards and Jira",
          "Responsible for logging and monitoring"
        ],
      },
      {
        title: "Data / Insights Analyst",
        period: "August 2019 - January 2020",
        company: "DTY Consulting Limited",
        description: [
          "Using advanced analytics practices in Microsoft Power BI to delve deeper into trends and patterns in the data.",
          "Importing data from SQL Server DB, Azure SQL DB to Power BI to generate reports.",
          "Created DAX queries to generate computed columns in Power BI.",
          "ETL processing with SQL and Reports/Visualization with Microsoft Power BI and SSRS",
          "Published Power BI Reports in the required originations and Made Power BI Dashboards available in Web clients and mobile apps",
          "Collaborating with Business analysts and End users.",
          "Maintain strict confidentiality of the firm’s internal and personnel affairs as well as those of our clients.",
          "Designing and building interactive and visual dashboards for delivering insightful information to senior leadership.",
          "Design, develop, implement, and maintain interactive, online reporting solutions for the enterprise.",
          "Used Power BI, Power Pivot to develop data analysis prototype, and used Power View and Power Map to visualize reports.",
          "Continuous Data modelling and reporting.",
          "Understanding business processes and connects business processes to data, also telling 'stories' behind data by analyzing entity relationship diagrams",
          "Creating suites of automated reports and dashboards by which business is managed effectively.",
        ],
      },
      {
        title: "Data Analyst",
        period: "October 2018 - August 2019",
        company: "Nissan Tailgate.",
        description: [
          "Worked with external stakeholders to understand and investigate feedback into the design, development and manufacturing service  provided.",
          "Using data visualisation practices to analyse findings and create suggestions for strategic and operational improvements and changes.",
          "Builds reports using data visualization tools with multi-dimensional analysis to explore the performance of the project.",
          "Continuous reporting and visualizations with Power BI.",
          "Weekly/monthly reports as required.",
          "Ensured project baseline data is recorded and deviations are recorded for analysis.",
          "Contributing to project planning, budgeting, and overall strategy.",
        ],
      },
      {
        title: "Senior Instrumentation/Electrical Engineer",
        period: "January 2015 - October 2018",
        company: "Exxon-Mobil FPSO DEEP WATER PROJECT- USAN, (OML 138 Offshore).",
        description: [
          "In charge of the instrumentation and electrical department.",
          "Reported to the Engineering Manager on all scheduled and intervention maintenance jobs.",
          "Monitoring and closing all maintenance Jobs within specified duration on SAP with strict adherence to safety.",
          "Job Planning and scheduling by the preparation of ePTW (Electronic Permit-to-work system).",
          "Maintenance schedules, Monthly reports for Fire and Gas equipment on the FPSO.",
          "Ordering and tracking of spare parts using the Computer Maintenance Management System (CMMS) and SAP (IPES & UNISUP) system.",
          "Installation, Design review, Pre-commissioning and commissioning of newly installed Process equipment on the FPSO using requisite API codes and standards.",
          "Mentoring and training of junior engineers and technicians on the Job, as well as some classroom sessions to discuss technical challenges and improvements.",
          "Responsible for the technical support within the section.",

        ],
      },
      {
        title: "Instrumentation Engineer",
        period: "June 2012 - January 2015",
        company: "Exxon-Mobil FPSO DEEP WATER PROJECT- USAN, (OML 138 Offshore).",
        description: [
          "Organized and execute on-site instrumentation design and maintenance jobs.", 
          "Preventive maintenance on F&G Instruments such as: Inergen fire systems, water mist System, Gas Detectors – IR 7000 and Open Part Drager types, Flame detectors- smart eye, NTDs Oil mist smoke detectors.",
          "Operation & Maintenance of BDV’s, ROV’s, LCV’s, SDV’s and ESDV’s. General Valve maintenance and repairs in accordance to Philosophy of operations.",
          "Troubleshooting Process Instrumentation.",
          "Calibrated and inspected Process Instrumentation and Control Equipment.", 
          "Pre-Commissioning, Commissioning of instruments on field.",
          
          
        ],
      },
    ],
    academic: [
      {
        career: "Electronic Systems Engineering B.Eng(HONS)",
        date: "2017",
        institution: "University of Portsmouth",
      },
      {
        career: "Build and Deploy Containerized Apps with Github Actions",
        date: "2021",
        institution: "Linkedin Learning",

      },
      {
        career: "Azure DevOps Engineer",
        date: "2021",
        institution: "Microsoft Power to Fly Challenge",
      },
    ],
        
    proyects: [
      {
        name: "Deployment Strategy for the platform",
        company: "Container Code for DCA",
        period: "2020",
        description: [
          "Building CI/CD pipelines for static and full stack applications.",
        ],
      },
      {
        name: "Powerful GitOps Workflow for the platform",
        company: "Container Code.",
        period: "2021",
        description: [
          "Created a CI/CD pipeline using GitHub Actions to automate the deployment of the GKE platform",
        ],
      },
    ],
  },
};
